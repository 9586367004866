import React from "react";
import Layout from "../components/layout";
import styles from "../styles/pages.module.css";

export default function Tnc() {
	return (
		<Layout>
			<div className={styles.bodyText}>
				<h1>Terms and Conditions</h1>
				<span><i>Last Updated: 1-Dec-2024</i></span>
				<br/>
				<br/>
				<p>
					Welcome to <b>DebjyotiAcharjee.com</b>. By accessing or
					using this website, you agree to comply with and be bound by
					the following terms and conditions. Please read them
					carefully. If you do not agree with these terms, you should
					not use this website.
				</p>

				<ol>
					<li>
						<b>Acceptance of Terms</b>
						By using this website, you acknowledge that you have
						read, understood, and agree to be bound by these Terms
						and Conditions, as well as our Privacy Policy.
					</li>

					<li>
						<b>Changes to Terms</b>
						These Terms and Conditions may change from time to time.
						Any changes will be effective immediately upon posting
						on this page. Your continued use of the website after
						any modifications indicates your acceptance of the new
						terms.
					</li>

					<li>
						<b>Use of the Website</b>
						You agree to use this website for lawful purposes only.
						You agree not to use the website in any way that may
						cause damage to the website or impair its availability
						or accessibility.
					</li>

					<li>
						<b>Intellectual Property</b>
						All content on this website, including text, graphics,
						logos, and images, is the property of{" "}
						<b>DebjyotiAcharjee.com</b> and is protected by
						copyright and intellectual property laws. You may not
						reproduce, distribute, or create derivative works from
						any content on this website without prior written
						permission.
					</li>

					<li>
						<b>User Accounts</b>
						If you create an account on this website, you are
						responsible for maintaining the confidentiality of your
						account information and for restricting access to your
						computer. You agree to accept responsibility for all
						activities that occur under your account.
					</li>

					<li>
						<b>Third-Party Links</b>
						This website may contain links to third-party websites.
						We do not endorse or assume any responsibility for the
						content or practices of these websites. Your use of any
						linked website is at your own risk.
					</li>

					<li>
						<b>Limitation of Liability</b>
						In no event shall <b>DebjyotiAcharjee.com</b> be liable
						for any direct, indirect, incidental, special,
						consequential, or punitive damages arising from your use
						of the website or any content therein.
					</li>

					<li>
						<b>Governing Law</b>
						These Terms and Conditions are governed by and construed
						in accordance with the laws of West Bengal/India. Any
						disputes arising under or in connection with these terms
						shall be subject to the exclusive jurisdiction of the
						courts of West Bengal/India.
					</li>

					<li>
						<b>Contact Information</b>
						If you have any questions about these Terms and
						Conditions, please contact at:
						<i>itsme@debjyotiacharjee.com</i>
					</li>
				</ol>
			</div>
		</Layout>
	);
}
